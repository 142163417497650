package reactbuild

import org.scalajs.dom
import org.scalajs.dom.ServiceWorkerGlobalScope.{ self => scope }
import scala.scalajs.js
import scala.concurrent.ExecutionContext.Implicits.global

final object Main {
  private val granted = "granted"

  final def main(args: Array[String]): Unit = {
    if (js.isUndefined(scope.registration)) {} else {
      //dom.experimental.Notification.requestPermission(js.native).asInstanceOf[js.Promise[String]].toFuture
      /*result => {
        if (result == granted) {
        }
    })*/
      /*for {
        _ <- dom.window.navigator.serviceWorker.ready.toFuture
      } yield */
      // scope.oninstall
      scope.onactivate = (event) => {
        //scope.registration.showNotification("hey !")
      }
      /*scope.setInterval(
        () => {
          scope.registration.showNotification("hey !")
        },
        2500)*/
    }
  }
}
